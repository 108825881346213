


















































































































































































































































































































































































































































.tollfree-info {
  .wizard-btn {
    color: #fff;
    background-color: #4284f4;
    border-color: #4284f4;
  }

  .multiselect--disabled {
    background-color: #d0d0d0 !important;

    .multiselect__tags,
    .multiselect__single,
    .multiselect__select {
      background-color: #d0d0d0 !important;
    }
  }
}
